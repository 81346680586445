import { useCallback } from "react";

import Typography, { ITypo } from "components/design_system/Typography";
import I18nStore from "stores/I18nStore";
import { Item } from "types/Item";

import classes from "./classes.module.scss";

type IProps = {
	items: Item[];
	selected: string;
	onChange: (sku: string) => void;
	classNames?: React.HTMLAttributes<HTMLDivElement>["className"][];
};

export default function ItemDeclinations({ items, selected, onChange, classNames }: IProps) {
	const handleClick = useCallback(
		(e: React.MouseEvent) => {
			const target = e.target as HTMLElement;
			const sku = target.getAttribute("data-sku") as string;
			if (!sku) return;
			onChange(sku);
		},
		[onChange],
	);
	return (
		<div className={[classes["root"], ...(classNames ?? [])].join(" ")}>
			<div className={classes["scrollable"]}>
				{items
					.sort((item) => {
						return item.ordering === "Primaire" ? -1 : 1;
					})
					.map((item) => {
						return (
							<div className={classes["item-container"]} data-selected={(selected === item.sku).toString()} onClick={handleClick} key={item.sku}>
								<div
									className={classes["item-image"]}
									style={{ backgroundImage: `url("products_images_v2/${item.sku}/small.jpg` }}
									data-sku={item.sku}
								/>
								<div className={classes["item-color"]} data-sku={item.sku}>
									<Typography typo={ITypo.TEXT_XS_REGULAR}>
										{I18nStore.getInstance().lang === "fr" ? item.couleurs_fr : item.couleurs_en}
									</Typography>
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
}
