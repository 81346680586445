import { SearchItemObj } from "components/pages/Search";
import I18nStore from "stores/I18nStore";

import Typography, { ITypo, ITypoColor } from "../Typography";
import classes from "./classes.module.scss";

type IProps = {
	item: SearchItemObj;
	onOpenItem: (e: React.MouseEvent) => void;
};

export default function SearchItem({ item, onOpenItem }: IProps) {
	return (
		<div className={classes["root"]}>
			<div
				className={classes["image-container"]}
				style={{ backgroundImage: `url("products_images_v2/${item.item.sku}/medium.jpg` }}
				onClick={onOpenItem}
				data-sku={item.item.sku}
			/>
			<div className={classes["subitems-container"]}>
				{item.subItems.slice(0, 3).map((subItem) => {
					return (
						<div
							onClick={onOpenItem}
							data-sku={subItem.sku}
							key={subItem.sku}
							className={classes["subitem-image-container"]}
							style={{ backgroundImage: `url("products_images_v2/${subItem.sku}/small.jpg` }}
						/>
					);
				})}
			</div>
			<div className={classes["info-container"]} onClick={onOpenItem} data-sku={item.item.sku}>
				<div className={classes["brand-container"]}>
					<Typography typo={ITypo.TEXT_MD_REGULAR} color={ITypoColor.BLUE_GREEN} className={classes["name-text"]}>
						{item.item.marque}
					</Typography>
				</div>
				<div className={classes["name-container"]}>
					<Typography typo={ITypo.TEXT_MD_BOLD} className={classes["name-text"]}>
						{I18nStore.getInstance().lang === "fr" ? item.item.nom_produit_fr : item.item.nom_produit_en}
					</Typography>
				</div>
				<div className={classes["prices-container"]}>
					<Typography typo={ITypo.TEXT_LG_SEMIBOLD}>{item.item.prix.toFixed(2)}€</Typography>
				</div>
			</div>
		</div>
	);
}
