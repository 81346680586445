"use client";

import EventEmitter from "services/EventEmitter";
import { Item } from "types/Item";
import items from "../assets/products_v4.json";

export default class ProductsStore {
	public static readonly instance = new this();
	protected readonly event = new EventEmitter();
	private products: Item[] = items;

	public getProducts() {
		return this.products;
	}

	public setProducts(products: Item[]) {
		this.products = products;
		this.event.emit("change", products);
	}

	public onChange(callback: (products: Item[]) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}
}
