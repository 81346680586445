import { BoundingBox } from "@mediapipe/tasks-vision";

import { useDebounce, useOrientation, useWindowSize } from "@uidotdev/usehooks";
import Typography, { ITypo, ITypoColor } from "components/design_system/Typography";
import React, { useCallback, useEffect, useRef, useState } from "react";
import HistoryService from "services/HistoryService";
import I18nStore from "stores/I18nStore";
import classes from "./classes.module.scss";
// import products from "../../assets/products.json";
import ItemDrawerStore from "stores/ItemDrawerStore";
import { Item } from "types/Item";
import products from "../../assets/products_v4.json";
export type IFacingModeType = "user" | "environment";

type IProps = {
	facingMode: IFacingModeType;
	onDetect: (points: Point[]) => void;
	setProgress: React.Dispatch<React.SetStateAction<number>>;
	onReady: React.Dispatch<React.SetStateAction<boolean>>;
	isPaused: boolean;
};

type IVideoSize = {
	width: number;
	height: number;
};

export type Point = {
	id: number;
	boundingBox: BoundingBox;
	name: string;
	frameLastSeen: number;
	x: number;
	y: number;
	score: number;
};

const isIPhone = navigator.userAgent.match(/iPhone/i);
const isAndroid = navigator.userAgent.match(/Android/i);
const isMobile = isIPhone || isAndroid;

function WebcamAi({ facingMode = "environment", onDetect, setProgress, onReady, isPaused }: IProps) {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const size = useWindowSize();
	const videoRef = useRef<HTMLVideoElement>(null);

	const [videoSize] = useState<IVideoSize>({ width: size.width ?? 0, height: size.height ?? 0 });
	const debouncedResize = useDebounce(size.width && size.height ? size.width.toString().concat(size.height.toString()) : undefined, 300);
	const imageSrc = useRef<string | null>(null);
	const [showImage, setShowImage] = useState<boolean>(false);
	const { type: orientationType } = useOrientation();
	const [showHeart, setShowHeart] = useState<boolean>(I18nStore.getInstance().lang === "en");

	const videoStream = useRef<MediaStream | null>(null);
	const [pictureBlurry, setPictureBlurry] = useState<boolean>(false);
	videoSize.width = size.width ?? 0;
	videoSize.height = size.height ?? 0;

	if (size.width ?? 0 < (size.height ?? 0)) {
		videoSize.width = size.height ?? 0;
		videoSize.height = size.width ?? 0;
	}

	if (orientationType === "landscape-primary" || orientationType === "landscape-secondary") {
		videoSize.width = size.width ?? 0;
		videoSize.height = size.height ?? 0;
	}

	useEffect(
		() =>
			I18nStore.getInstance().onChange(() => {
				setShowHeart(I18nStore.getInstance().lang === "en");
			}),
		[],
	);

	// useEffect(() => {
	// 	preloadTfLiteFile(setProgress, false)
	// 		.then(() => setIsModelLoaded(true))
	// 		.catch((error) => console.error("Erreur lors du chargement du fichier", error));
	// }, [setProgress]);

	// const predictWebcam = useCallback(() => {
	// 	if (!enablePredicating || !videoRef.current || !objectDetector || isPaused) return;
	// 	try {
	// 		if (videoRef.current.currentTime === lastVideoTime) {
	// 			requestAnimationFrameId.current = window.requestAnimationFrame(predictWebcam);
	// 			return;
	// 		}
	// 		currentFrame++;
	// 		lastVideoTime = videoRef.current.currentTime;

	// 		const startTimeMs = performance.now();
	// 		const detections = objectDetector.detectForVideo(videoRef.current, startTimeMs, {});
	// 		const newPoints = createPoints(points, detections, videoSize, camWidth, camHeight);

	// 		setPoints(newPoints);
	// 		onDetect(newPoints);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}

	// 	requestAnimationFrameId.current = window.requestAnimationFrame(predictWebcam);
	// }, [enablePredicating, objectDetector, onDetect, points, camHeight, camWidth, videoSize, isPaused]);

	const startStream = useCallback(async () => {
		if (!videoSize.height || !videoSize.width || !canvasRef.current) return;

		if (videoRef.current && debouncedResize) {
			enableCam(videoRef.current, facingMode, videoSize)
				.then((values) => {
					videoRef.current?.play();
					if (isMobile && values.height > values.width) {
						canvasRef.current!.height = values.width;
						canvasRef.current!.width = values.height;

						return;
					}

					canvasRef.current!.width = values.height;
					canvasRef.current!.height = values.width;
				})
				.catch((err) => console.info(err));
		}
	}, [debouncedResize, facingMode, videoSize]);

	function stopStream() {
		if (!videoStream.current) return;
		videoStream.current.getTracks().forEach((track) => track.stop());
		videoStream.current = null;

		if (videoRef.current) {
			videoRef.current.pause();
			videoRef.current.src = "";
		}
	}

	/**
	 * @description Ask for user's webcam
	 */
	async function enableCam(video: HTMLVideoElement, facingMode: IFacingModeType = "environment", videoSize: IVideoSize, reverse = false) {
		if (videoStream.current) {
			video.pause();
			video.src = "";
			videoStream.current.getTracks().forEach((track) => track.stop());
		}
		return new Promise<{ width: number; height: number; stream: MediaStream }>((resolve, reject) => {
			const aspectRatio = videoSize.width / videoSize.height;

			navigator.mediaDevices
				.getUserMedia({
					video: {
						facingMode,
						aspectRatio: {
							exact: aspectRatio,
						},
					},
					audio: false,
				})
				.then(function (stream) {
					videoStream.current = stream;
					const trackSettings = stream.getVideoTracks()[0].getSettings();
					const width = trackSettings.width;
					const height = trackSettings.height;

					if (!width || !height) return;
					video.srcObject = stream;
					const onLoadeddata = () => {
						video.removeEventListener("loadeddata", onLoadeddata);
						resolve({ width, height, stream });
					};
					video.addEventListener("loadeddata", onLoadeddata);
				})
				.catch((err) => reject(err));
		});
	}

	const closeImage = useCallback(() => {
		imageSrc.current = null;
		onDetect([]);
		setShowImage(false);
		setPictureBlurry(false);
	}, [onDetect]);

	const takePhoto = useCallback(() => {
		if (!videoRef.current || !canvasRef.current) return;

		if (showImage) {
			closeImage();
			return;
		}

		const context = canvasRef.current.getContext("2d")!;
		context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

		// Convert the canvas to a blob (image file)
		canvasRef.current.toBlob((blob) => {
			if (!blob) return;

			// Create a form data object to hold the file
			const formData = new FormData();
			formData.append("file", blob, "photo.png");

			// Send the image file to the backend /embeddings endpoint
			fetch("https://app-111f377c-4a8d-4baa-8fe4-de5cc1ebfa29.cleverapps.io/api/v1/embeddings?neighbors=1", {
				method: "POST", // Change to POST instead of GET to send the file
				body: formData,
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.nearest_neighbors && data.nearest_neighbors.length > 0) {
						const imageClass = data.nearest_neighbors[0].image_class; // Get the first neighbor (or handle multiple)
						console.log("imageClass", imageClass);

						const obj = products.find((product) => product.sku === imageClass) as Item;
						console.log("Nearest neighbor:", obj);

						HistoryService.instance.add(obj, "scan");
						ItemDrawerStore.instance.openDrawerItem(obj.sku);
					}
				})
				.catch((error) => {
					console.error("Error fetching similar images:", error);
				});
		}, "image/png");
	}, [closeImage, showImage]);

	// /**
	//  * @description Predict webcam when isPredicating
	//  */
	// useEffect(() => {
	// 	if (!enablePredicating) return;
	// 	if (requestAnimationFrameId.current) {
	// 		window.cancelAnimationFrame(requestAnimationFrameId.current);
	// 	}
	// 	requestAnimationFrameId.current = window.requestAnimationFrame(predictWebcam);
	// }, [enablePredicating, predictWebcam]);

	/**
	 * @description Ask user to enable webcam at didMount
	 */
	useEffect(() => {
		startStream();

		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				startStream();
			} else {
				stopStream();
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			stopStream();
		};
	}, [videoSize, facingMode, debouncedResize, onReady, startStream]);

	useEffect(() => {
		const removeOnChange = ItemDrawerStore.instance.onDrawerOpenChange((isOpened) => {
			if (!isOpened) {
				closeImage();
			}
		});

		return () => {
			removeOnChange();
		};
	}, [closeImage]);

	return (
		<div className={classes["root"]} style={{ height: size.height?.toString().concat("px"), width: size.width?.toString().concat("px") }}>
			<video
				className={classes["video"]}
				ref={videoRef}
				width={size.width?.toString().concat("px")}
				height={size.height?.toString().concat("px")}
				// autoPlay
				playsInline></video>

			<div className={classes["take-photo-button-container"]}>
				<Typography typo={ITypo.TEXT_MD_MEDIUM} color={ITypoColor.WHITE}>
					{I18nStore.getInstance().translate("pages.scan.take_picture")}
				</Typography>
				{/* {showImage && (
					<Typography typo={ITypo.TEXT_MD_MEDIUM} color={ITypoColor.WHITE}>
						{I18nStore.getInstance().translate("pages.scan.retry")}
					</Typography>
				)}
				{!showImage && (
					<Typography typo={ITypo.TEXT_MD_MEDIUM} color={ITypoColor.WHITE}>
						{I18nStore.getInstance().translate("pages.scan.take_picture")}
					</Typography>
				)} */}
				<button className={classes["take-photo-button"]} onClick={takePhoto}>
					<div className={classes["inner-take-photo-button"]} style={showHeart ? { maskImage: `url("/images/heart.png")` } : {}}>
						{/* <SearchIcon className={classes["search-icon"]}></SearchIcon> */}
					</div>
				</button>
			</div>

			{/* {showImage && (
				<div className={classes["cross-container"]}>
					<Cross color={ITypoColor.WHITE} onClick={closeImage} />
				</div>
			)} */}

			{/* {process.env["REACT_APP_ENV"] !== "production" && (
				<div className={classes["threshold-container"]}>
					<input
						type="range"
						min="0"
						max="1"
						step="0.01"
						value={thresholdScore}
						onChange={(event) => {
							LocalStorageService.getInstance().items.thresholdScore.set(event.target.value);
							window.location.reload();
						}}></input>
					<Typography typo={ITypo.TEXT_MD_BOLD} color={ITypoColor.BLACK}>
						{thresholdScore}
					</Typography>
				</div>
			)} */}

			{showImage && pictureBlurry && (
				<div className={classes["picture-blurry"]}>
					<Typography typo={ITypo.TEXT_MD_BOLD} color={ITypoColor.BLACK}>
						{I18nStore.getInstance().translate("pages.scan.picture_blurry")}
					</Typography>
				</div>
			)}

			<canvas ref={canvasRef} className={classes["canvas"]}></canvas>
			{showImage && <img src={imageSrc.current ?? ""} alt="" className={classes["image"]} ref={imageRef} />}
		</div>
	);
}

export default React.memo(WebcamAi);

// const preloadTfLiteFile = (() => {
// 	let response: Response | null = null;

// 	return async (setProgress: (progress: number) => void, reset: boolean) => {
// 		if (reset) response = null;
// 		if (response) return response;

// 		response = await fetch(model);

// 		if (!response.ok) {
// 			throw new Error(`Erreur HTTP! Statut : ${response.status}`);
// 		}

// 		const contentLength = response.headers.get("Content-Length");
// 		const totalSize = contentLength ? parseInt(contentLength, 10) : 0;

// 		if (!response.body) throw new Error("No body found");

// 		const reader = response.body.getReader();
// 		let bytesRead = 0;

// 		while (true) {
// 			const { done, value } = await reader.read();
// 			if (done) break;
// 			bytesRead += value!.byteLength;
// 			// Calcul du pourcentage de chargement
// 			const currentProgress = (bytesRead / totalSize) * 100;
// 			setProgress(currentProgress);
// 		}
// 	};
// })();

// /**
//  * @description Initialize ObjectDetector
//  */
// async function initializeObjectDetector(runningMode: RunningMode, scoreThreshold: number) {
// 	const visionFilesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm");
// 	return ObjectDetector.createFromOptions(visionFilesetResolver, {
// 		baseOptions: {
// 			// modelAssetPath: `https://storage.googleapis.com/mediapipe-models/object_detector/efficientdet_lite0/float16/1/efficientdet_lite0.tflite`,
// 			modelAssetPath: model,
// 			delegate: "GPU",
// 		},
// 		scoreThreshold,
// 		runningMode: runningMode,
// 	});
// }

// function matchObject(existingPoints: Point[], detected: Detection): { id: number | null; index: number | null } {
// 	let bestMatchId: number | null = null;
// 	let index: number | null = null;
// 	let highestIoU = 0;
// 	if (!detected.boundingBox) return { id: null, index: null };

// 	const boundingBox = detected.boundingBox;

// 	existingPoints.forEach((point, i) => {
// 		const currentIoU = calculateIoU(point.boundingBox, boundingBox);

// 		if (currentIoU > highestIoU && currentIoU >= iouThreshold) {
// 			highestIoU = currentIoU;
// 			bestMatchId = point.id;
// 			index = i;
// 		}
// 	});

// 	return { id: bestMatchId, index };
// }

// function calculateIoU(bbox1: BoundingBox, bbox2: BoundingBox): number {
// 	const box1 = {
// 		x1: bbox1.originX,
// 		y1: bbox1.originY,
// 		x2: bbox1.originX + bbox1.width,
// 		y2: bbox1.originY + bbox1.height,
// 	};
// 	const box2 = {
// 		x1: bbox2.originX,
// 		y1: bbox2.originY,
// 		x2: bbox2.originX + bbox2.width,
// 		y2: bbox2.originY + bbox2.height,
// 	};

// 	const x_overlap = Math.max(0, Math.min(box1.x2, box2.x2) - Math.max(box1.x1, box2.x1));
// 	const y_overlap = Math.max(0, Math.min(box1.y2, box2.y2) - Math.max(box1.y1, box2.y1));
// 	const overlapArea = x_overlap * y_overlap;

// 	const area1 = bbox1.width * bbox1.height;
// 	const area2 = bbox2.width * bbox2.height;
// 	const unionArea = area1 + area2 - overlapArea;

// 	return overlapArea / unionArea;
// }

// const createPoints = (() => {
// 	let nextObjectId = 0;

// 	return (existingPoints: Point[], detection: ObjectDetectorResult, videoSize: IVideoSize, camWidth: number, camHeight: number) => {
// 		// Track which points are currently detected
// 		const newPoints = [...existingPoints];

// 		detection.detections.forEach((detected) => {
// 			const categories = detected.categories.sort((a, b) => b.score - a.score);
// 			if (!detected.boundingBox) return;
// 			const highestCategory = categories[0];

// 			const centerX = detected.boundingBox.originX + detected.boundingBox.width / 2;
// 			const centerY = detected.boundingBox.originY + detected.boundingBox.height / 2;
// 			let x = (centerX / camWidth) * videoSize.width;
// 			let y = (centerY / camHeight) * videoSize.height;

// 			const { index } = matchObject(newPoints, detected);

// 			if (index === null) {
// 				const newId = nextObjectId++;

// 				newPoints.push({
// 					id: newId,
// 					boundingBox: detected.boundingBox,
// 					frameLastSeen: currentFrame,
// 					name: highestCategory.categoryName,
// 					x,
// 					y,
// 					score: highestCategory.score,
// 				});
// 				return;
// 			}
// 			let existingPoint = { ...newPoints[index] };
// 			const previousX = existingPoint.x;
// 			const previousY = existingPoint.y;

// 			const distance = Math.sqrt(Math.pow(previousX - x, 2) + Math.pow(previousY - y, 2));
// 			const maxDistance = detected.boundingBox.width / 2;

// 			if (highestCategory.categoryName !== existingPoints[index].name && highestCategory.score > existingPoint.score) {
// 				existingPoint.name = highestCategory.categoryName;
// 				existingPoint.score = highestCategory.score;
// 			}
// 			if (distance > Math.max(maxDistance, distanceThreshold)) {
// 				existingPoint.x = x;
// 				existingPoint.y = y;
// 			}
// 			existingPoint.frameLastSeen = currentFrame;
// 			existingPoint.boundingBox = detected.boundingBox;

// 			newPoints[index] = existingPoint;
// 		});

// 		return newPoints.filter((point) => currentFrame - point.frameLastSeen <= constancyThreshold);
// 	};
// })();
