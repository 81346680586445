import { useCallback } from "react";

import I18nStore from "stores/I18nStore";
import ItemDrawerStore from "stores/ItemDrawerStore";
import { BasketItem as BasketItemType } from "types/Item";

import Trash from "../Icons/Trash";
import NumberPicker from "../NumberPicker";
import Typography, { ITypo } from "../Typography";
import classes from "./classes.module.scss";

type IProps = {
	item: BasketItemType;
	onItemQuantityChange: (quantity: number, sku: string) => void;
	onDelete: (id: string) => void;
};
export default function BasketItem({ item, onItemQuantityChange, onDelete }: IProps) {
	const handleTrashClick = useCallback(() => {
		onDelete(item.sku);
	}, [item.sku, onDelete]);

	const onQuantityChange = useCallback(
		(quantity: number) => {
			onItemQuantityChange(quantity, item.sku);
		},
		[item.sku, onItemQuantityChange],
	);

	const openItemDrawer = useCallback(() => {
		ItemDrawerStore.instance.openDrawerItem(item.sku);
	}, [item.sku]);

	return (
		<div className={classes["root"]}>
			<div className={classes["image-container"]}>
				<img src={`products_images_v2/${item.sku}/small.jpg`} alt={item.nom_produit_fr} onClick={openItemDrawer} />
			</div>
			<div className={classes["info-container"]}>
				<div className={classes["top-infos"]} onClick={openItemDrawer}>
					<div className={classes["name-container"]}>
						<Typography typo={ITypo.TEXT_MD_BOLD} className={classes["name-text"]}>
							{I18nStore.getInstance().lang === "fr" ? item.nom_produit_fr : item.nom_produit_en}
						</Typography>
					</div>
					<div className={classes["prices-container"]} onClick={openItemDrawer}>
						<Typography typo={ITypo.TEXT_LG_SEMIBOLD}>{item.prix.toFixed(2)}€</Typography>
					</div>
				</div>
				<div className={classes["quantity-container"]}>
					<NumberPicker defaultValue={item.quantity} onChange={onQuantityChange} />
					<Trash onClick={handleTrashClick} />
				</div>
			</div>
		</div>
	);
}
